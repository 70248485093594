import request from '@/utils/request';
export var fileTransferHeaders = {};
export var defaultBrandData = {
  id: 0,
  enabled: true,
  name: '',
  image: '',
  description: ''
};
export var getBrands = function getBrands(params) {
  return request({
    url: '/brands',
    method: 'get',
    params: params
  });
};
export var getBrandById = function getBrandById(id) {
  return request({
    url: "/brands/".concat(id),
    method: 'get'
  });
};
export var updateBrand = function updateBrand(id, data) {
  return request({
    url: "/brands/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteBrand = function deleteBrand(id) {
  return request({
    url: "/brands/".concat(id),
    method: 'delete'
  });
};
export var createBrand = function createBrand(data) {
  return request({
    url: '/brands/',
    method: 'post',
    data: data
  });
};